import {
    required,
    minLength,
    maxLength,
    email
} from 'vuelidate/lib/validators';

export const validationRules = {
    firstname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
        onlyLattersValidate(value) {
            return /^[a-zA-Z]*$/g.test(value)
        }
    },
    lastname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
        onlyLattersValidate(value) {
            return /^[a-zA-Z]*$/g.test(value)
        }
    },
    nickname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
    },
    email: {
        required,
        email,
        async emailExists(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.email.required || !this.$v.email.email) { return true; }
            const response = await this.checkEmail({ email: value })
            return new Promise((resolve) => {
                if (response.errors) {
                    this.$v.email.$params.emailExists = true
                    resolve(false)
                } else {
                    resolve(true)
                }
            })
        }
    },
    password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(50),
        async passwordStrengthTest(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.password.required || !this.$v.password.minLength || !this.$v.password.maxLength) {
                return true
            }
            const result = await this.validatePassword(value)
            this.$v.password.$params.passwordStrengthTest = result
            return result.value
        },
        spacesNotAllowed(value) {
            return value.indexOf(' ') === -1
        }
    }
}
