<template>
    <auth-layout>
        <h2
            slot="header"
            class="eister-modal__header-text"
        >
            Register
        </h2>
        <form
            slot="body"
            @submit.prevent="checkFormData"
        >
            <div class="eister-modal__input-mask">
                <div class="input-field input-row">
                    <div class="input-row__50">
                        <form-input
                            v-model="firstname"
                            :field.sync="firstname"
                            name="firstname"
                            legend="First name"
                            :prepend-icon="'account-icon'"
                            type="text"
                            :errors="validationMessage($v.firstname)"
                            :is-valid="$v.firstname.$dirty && !$v.firstname.$anyError"
                            @input.native="$v.firstname.$touch()"
                            @blur.native="$v.firstname.$touch()"
                        />
                    </div>

                    <div class="input-row__50">
                        <form-input
                            v-model="lastname"
                            :field.sync="lastname"
                            name="lastname"
                            legend="Last name"
                            :prepend-icon="'account-icon'"
                            type="text"
                            :errors="validationMessage($v.lastname)"
                            :is-valid="$v.lastname.$dirty && !$v.lastname.$anyError"
                            @input.native="$v.lastname.$touch()"
                            @blur.native="$v.lastname.$touch()"
                        />
                    </div>
                </div>
                <div class="input-field input-row">
                    <form-input
                        v-model="nickname"
                        :field.sync="nickname"
                        name="nickname"
                        legend="Nick name"
                        :prepend-icon="'account-icon'"
                        type="text"
                        :errors="validationMessage($v.nickname)"
                        :is-valid="$v.nickname.$dirty && !$v.nickname.$anyError"
                        @input.native="$v.nickname.$touch()"
                        @blur.native="$v.nickname.$touch()"
                    />
                </div>
                <div class="input-field">
                    <form-input
                        v-model.trim="emailValue"
                        legend="Email address"
                        prepend-icon="EmailOutlineIcon"
                        type="text"
                        name="email"
                        :field.sync="emailValue"
                        :errors="validationMessage($v.email)"
                        :is-valid="$v.email.$dirty && !$v.email.$anyError"
                        :loading="loadEmail"
                        @input.native="$v.email.$touch()"
                        @onChange="loadEmail = true"
                    />
                </div>

                <div
                    class="input-field"
                    :style="{ marginBottom: password.length > 7 ? '10px' : '35px' }"
                >
                    <!-- password block -->
                    <form-input
                        v-model="$v.password.model"
                        :field.sync="password"
                        name="password"
                        legend="Password"
                        prepend-icon="LockIcon"
                        :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                        :type="showPassword ? 'text' : 'password'"
                        :errors="validationMessage($v.password)"
                        :is-valid="$v.password.$dirty && !$v.password.$anyError"
                        @clicked="showPassword = !showPassword"
                        @input.native="$v.password.$touch()"
                        @blur.native="$v.password.$touch()"
                        @onChange="testPassword"
                    />
                </div>
                <progress-linear
                    v-if="password.length > 7"
                    :style="{marginBottom: '30px'}"
                    :validate="passwordValidation"
                />
                <div class="input-field">
                    <phone-number />
                </div>
            </div>
            <div class="input-field remember-me">
                Back to
                <router-link
                    to="/login"
                    class="forgot-password"
                >
                    Login
                </router-link>
                or
                <router-link
                    to="/forgot-password"
                    class="forgot-password"
                >
                    Forgot your password?
                </router-link>
            </div>
            <custom-button
                default
                :disabled="loading || isFormValid || !verified"
                class="button btn-base_colored button-registration"
                :loading="loading"
                :type="'submit'"
                width="100%"
            >
                Register
            </custom-button>
        </form>
    </auth-layout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout';
import ProgressLinear from '@/components/ProgressLinear'

// Validation
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/Register/Rules'
import { formMessages } from '@/validation/Register/Messages'
import passwordValidationMixin from '@/mixins/passwordValidationMixin'
import { validationRules as phoneRules } from '@/validation/phone/Rules.js'

import { debounce } from 'lodash'

// store
import { createNamespacedHelpers, mapState } from 'vuex'
import { mapPhoneFields } from '@/store/modules/phone'
import { mapAdminFormFields } from '@/store/modules/auth'
import {
    ACTION_ADMIN_CHECK_EMAIL,
    ACTION_CREATE_ADMIN,
    ACTION_LOGIN_ADMIN
} from '@/store/modules/auth/action-types.js'

const { mapActions: mapAdminActions } = createNamespacedHelpers('auth')

export default {
    name: 'IndexVue',
    components: {
        AuthLayout,
        ProgressLinear
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        passwordValidationMixin
    ],
    validations: {
        ...validationRules,
        ...phoneRules
    },
    data() {
        return {
            emailValue: '',
            loading: false,
            loadEmail: false,
            showPassword: false,
            passwordValidation: { strength: 0 }
        }
    },
    computed: {
        ...mapAdminFormFields([
            'form_data.firstname',
            'form_data.lastname',
            'form_data.nickname',
            'form_data.email',
            'form_data.password'
        ]),
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ]),
        isFormValid() {
            return this.$v.$invalid
        }
    },
    watch: {
        emailValue: debounce(function (newEmail) {
            this.email = newEmail
            this.loadEmail = false
        }, 300)
    },
    mounted() {
        this.emailValue = this.email
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapAdminActions([
            ACTION_ADMIN_CHECK_EMAIL,
            ACTION_CREATE_ADMIN,
            ACTION_LOGIN_ADMIN
        ]),
        testPassword() {
            this.validatePassword(this.password).then((result) => { this.passwordValidation = result })
        },
        async checkEmail(value) {
            const response = await this[ACTION_ADMIN_CHECK_EMAIL](value)
            return response
        },
        async checkFormData() {
            this.$v.$touch()
            if (this.isFormValid) { return }
            if (!this.verified) {
                this.$bus.$emit('showSnackBar', "Your did'n verify your phone", 'error')
                return
            }
            this.validate()
        },
        async submit() {
            this.loading = true
            try {
                await this[ACTION_CREATE_ADMIN]({
                    firstname: this.firstname,
                    lastname: this.lastname,
                    nickname: this.nickname,
                    email: this.email,
                    password: this.password
                })
                this.$bus.$emit('showSnackBar', 'Your account has been created', 'success')
                this.resetForm()
                this.$router.push({ name: 'login' })
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        resetForm() {
            this.firstname = ''
            this.lastname = ''
            this.nickname = ''
            this.email = ''
            this.password = ''
            this.phone_number = ''
            this.phone_code = null
        }
    }
}
</script>

<style lang="scss" scoped>
.forgot-password {
    color: $orange;
}
.button-registration{
    pointer-events: all !important;;
}
</style>
