export const formMessages = {
    required: () => 'Required',
    email: () => 'Must be a valid e-mail',
    emailExists: () => 'E-mail already exists',
    minLength: ({ $params }) => `Must be at least ${$params.minLength.min} characters.`,
    maxLength: ({ $params }) => `Must be less than ${$params.maxLength.max} characters.`,
    spacesNotAllowed: () => 'Spaces not allowed',
    onlyLattersValidate: () => 'Please enter only alphabets',
    passwordStrengthTest: ({ $params }) => $params.passwordStrengthTest.message
    // phoneValidated: () => 'Phone number is invalid'
};
